<template>
  <div class="mb-2">
    <v-layout wrap justify-center align-center>
      <!-- {{ userAnswers.filter(v=>v!=null).length }} -->
      <v-flex xs12 sm8 md5 class="px-4">
          <div v-if="questions.length > 0 && userAnswers.filter(v=>v.answer==false).length<3 && userAnswers.filter(v=>v!=null).length<20 && timeLeft>0">
            <!-- Quiz Questions -->
            <div class="d-flex jusftify-content">
              <v-btn text dense small color="primary" class="pa-0" to="home">
                <v-icon>mdi-home</v-icon>
                Asosiy</v-btn>
              <v-spacer></v-spacer>
              <div v-if="quizStarted && timeLeft > 0">
                <h4 style="color:red">{{ formatTime(timeLeft) }}</h4>
              </div>
            </div>
            <hr/>
            <div v-if="currentQuestionIndex < questions.length">
              <h4>{{ currentQuestion.question }}</h4>
              <img :src="getImagePath(currentQuestion ? currentQuestion.media.name : 'banner', 2)" style="max-width: 100%; margin-bottom:10px">
              <div v-for="(choice, index) in currentQuestion.choises" :key="index" class="mb-3 pa-1 choice-container"
                  :class="
                  userAnswers.filter(l=>l.index==currentQuestionIndex).length>0 &&
                  userAnswers.filter(l=>l.index==currentQuestionIndex)[0].choice === index &&
                  choice.answer
                  ? 'correct' 
                  : (userAnswers.filter(l=>l.index==currentQuestionIndex).length>0 && 
                  userAnswers.filter(l=>l.index==currentQuestionIndex)[0].choice === index &&
                  !choice.answer
                  ? 'incorrect' 
                  : 'default')"
                  style="border: 1px solid var(--v-borderc-base) !important; border-radius: 10px;">
                <label style="padding-left: 10px; display: flex; align-items: center; cursor: pointer; width: 100% ;">
                  <input
                    type="radio"
                    style="display: none;"
                    :value="index"
                    @click="setAnswer(index, choice.answer)"
                    :disabled="userAnswers[currentQuestionIndex]!=null "
                  />
                  {{ choice.text }}
                  
                </label>
               
              </div>
            </div>

            <!-- Display Result -->
            <!-- <v-row class="mt-4">
              <v-col>
                <button @click="submitResults">Testni yakunlash</button>
              </v-col>
              <v-spacer></v-spacer>
              <v-col>
                <v-btn color="success" @click="nextQuestion">Keyingi</v-btn>
              </v-col>
            </v-row> -->
            
                  <v-row class="mb-2 mt-2">
                    <div class="d-flex justify-center align-center" v-for="(q,qk) in questions">
                      <v-btn class="mb-1 ml-1" dense hide-details small :color="userAnswers.filter(l=>l.index==qk).length> 0 && userAnswers.filter(l=>l.index==qk)[0].answer === true ? 'success' : (userAnswers.filter(l=>l.index==qk).length> 0 && userAnswers.filter(l=>l.index==qk)[0].answer === false ? 'error' : currentQuestionIndex==qk ? 'orange darken-1' : 'default')" @click="setQuestion(qk)">{{ qk+1 }}</v-btn>
                    </div>
                    <!-- {{ userAnswers }} -->

                    <!-- {{ userAnswers.find(l=>l.index==currentQuestionIndex).choice }} -->
                    <!-- {{ currentQuestionIndex }} -->
                    <!-- {{ questions }} -->
                    <!-- <v-col>
                      <v-btn small color="error" @click="showTwaPopup">Chiqish</v-btn>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col class="flex-grow-0">
                      <v-btn small color="success" @click="prevQuestion">Avvalgi</v-btn>
                    </v-col>
                    <v-col class="flex-grow-0">
                      <v-btn small color="success" @click="nextQuestion">Keyingi</v-btn>
                    </v-col> -->
                  </v-row>
                  <v-fab-transition>
                    <v-btn
                      color="success"
                      fab
                      x-small
                      dark
                      bottom
                      left
                      @click="showHelpModal=true"
                      class="v-btn--floating"
                    >
                      <v-icon>mdi-lightbulb</v-icon>
                    </v-btn>
                  </v-fab-transition>
          </div>
          {{ }}
          <div v-if="userAnswers.filter(v=>v.answer==false).length>2" class="d-flex justif-center">
            <div>
              <div>
             <h3 style="text-align: center;">
              Afsus, {{ user.name }}! Siz <span style="color: red">{{ userAnswers.filter(v=>v.answer==false).length }}</span> ta xato qilib, Testdan o'ta olmadingiz!
             </h3> 
            </div>
            <img :src="getImagePath('lose3', 2)" alt="" width="100%">
            <div class="text-center">
              <v-btn small color="success" @click="reloadPage">Qayta boshlash</v-btn>
            </div>
            </div>
          </div>
          <div  v-if="userAnswers.length==20 && userAnswers.filter(v=>v!=null).length==20">
            <div>
             <h3 style="text-align: center;">
              Tabriklaymiz, {{ user.name }}! Siz <span style="color:green">{{ userAnswers.filter(v=>v.answer==true).length }}</span> ball to'plab, Testdan muvaffaqqiyatli o'tdingiz!
             </h3> 
            </div>
            <img :src="getImagePath('suc2', 2)" alt="" width="100%">
            <div class="text-center">
              <v-btn small color="success" @click="reloadPage">Qayta boshlash</v-btn>
            </div>
          </div>
          <div v-if="timeLeft===0">
            <h2 v-if="timeLeft === 0">Time's up! Your score: {{ score }}</h2>
            <h2 v-else>Your Score: {{ score }}</h2>
            <!-- <button @click="submitResults">Submit Results</button> -->
          </div>
        </v-flex>
    </v-layout>
    <v-dialog v-model="showHelpModal">
      <v-card style="background-color: var(--v-background-base) ">
        <!-- <v-card-title>
          <span class="text-h5">Use Google's location service?</span>
        </v-card-title> -->
        <v-card-text  class="pt-4" >
          {{ currentQuestion ? currentQuestion.description : '' }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            text
            @click="showHelpModal = false"
          >
            Yopish
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog" max-width="600">
      <v-card>
        <v-img :src="getImagePath(currentQuestion ? currentQuestion.media.name : 'banner', 2)" class="zoomed-image"></v-img>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { initDataUnsafe } from '@twa-dev/sdk';
import { db, collection, addDoc } from "../firebase";
import questionsData from "../assets/pdd.json";
import WebApp from "@twa-dev/sdk";

export default {
  name: "Todos",
  data() {
    return {
      isDarkTheme: this.$vuetify.theme.dark,
      user: {
        name: '',
        id: null,
      },
      questions: [],
      currentQuestionIndex: 0,
      userAnswers: [],
      quizStarted: false,
      timeLeft: 1500, // 5 minutes (in seconds)
      timerInterval: null,
      showHelpModal: false,
      dialog: false,
      categories: [
        {
          "id": 1,
          "name": "Umumiy Haydovchilik Majburiyatlari",
        },
        {
          "id": 2,
          "name": "Harakatni boshqarish va Signallar",
        },
        {
          "id": 3,
          "name": "Transport vositalarini boshqarish va Manyovr qilish",
        },
        {
          "id": 4,
          "name": "Chorrahalar va aylanma yo'llarda harakatlanish",
        },
        {
          "id": 5,
          "name": "Maxsus Haydovchilik Sharoitlari",
        },
        {
          "id": 6,
          "name": "Odamlar va Yuklarni Tashish",
        },
        {
          "id": 7,
          "name": "Transport Vositalarini Saqlash va Asboblardan Foydalanish",
        },
        {
          "id": 8,
          "name": "Yo'l Belgilari va Chiziqlar",
        },
        {
          "id": 9,
          "name": "Transport Vositalaridan Foydalanishni Taqiqlash va Cheklash",
        },
        {
          "id": 10,
          "name": "Hodisalar, Avariyalar va Favqulodda Vaziyatlar",
        },
        {
          "id": 11,
          "name": "Xavfsizlik Chegaralari",
        },
      ]
    };
  },
  computed: {
    currentQuestion() {
      return this.questions[this.currentQuestionIndex];
    },
    isAnswered() {
      return this.userAnswers[this.currentQuestionIndex] !== undefined;
    },
    score() {
      return this.userAnswers.reduce((score, answer, index) => {
        return score + (this.questions[index].choises[answer]?.answer ? 1 : 0);
      }, 0);
    },
    error() {
      return this.userAnswers.reduce((score, answer, index) => {
        return score + (this.questions[index].choises[answer]?.answer ? 0 : 1);
      }, 0);
    },
  },
  async created() {
    await this.initializeTelegramWebApp();
    this.loadQuestions();
  },
  methods: {
    openLink(){
      WebApp.openTelegramLink('https://t.me/lider_avtomaktab');
    },
    getImagePath(imageName, type) {
      if(type==2){
        return require('@/assets/img/' + imageName + '.jpg');
      }
      else{
        return require('@/assets/img/' + imageName + '.png');
      }
    },
    async initializeTelegramWebApp() {
      try {
        const data = WebApp.initDataUnsafe;
        this.user.name = data.user?.first_name || 'Anonymous';
        this.user.id = data.user?.id || 'Unknown ID';
      } catch (error) {
        console.error('Failed to initialize Telegram Web App:', error);
      }
    },
    loadQuestions() {
      // Shuffle and select up to 20 questions
      this.questions = questionsData.sort(() => 0.5 - Math.random()).slice(0, 20);
      this.startQuiz();
    },
    toggleTheme() {
      // Toggle the theme between light and dark
      this.isDarkTheme = !this.isDarkTheme;
      this.$vuetify.theme.dark = this.isDarkTheme;

      // Save the selected theme to localStorage
      localStorage.setItem('userTheme', this.isDarkTheme ? 'dark' : 'light');
    },
    startQuiz() {
      this.quizStarted = true;
      this.startTimer();
    },
    startTimer() {
      this.timerInterval = setInterval(() => {
        if (this.timeLeft > 0) {
          this.timeLeft--;
        } else {
          this.error = 3; // Auto-submit when time runs out
        }
      }, 1000); // Update every second
    },
    formatTime(seconds) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    },
    nextQuestion() {
      if (this.currentQuestionIndex < this.questions.length - 1) {
        this.currentQuestionIndex++;
      }
    },
    setQuestion(question){
      this.currentQuestionIndex = question;
    },
    setAnswer(index, isCorrect) {
      if (!this.isAnswered) {
        this.userAnswers[this.currentQuestionIndex] = ({'index': this.currentQuestionIndex, 'answer': isCorrect, 'choice': index});
      }
      setTimeout(() => {
        if(this.currentQuestionIndex!=19){
          this.currentQuestionIndex +=1;
        }
      }, 2000);
      
    // Bu yerda tanlov tanlanganida keyingi savolga o'tishni amalga oshirishingiz mumkin.
    // this.nextQuestion();
  },
    prevQuestion() {
      if (this.currentQuestionIndex !=0) {
        this.currentQuestionIndex--;
      }
    },
    showTwaPopup() {
      Telegram.WebApp.showPopup({
        title: "Testdan chiqish",
        message: "Agar chiqsangiz, test qaytdan boshlanadi!?",
        buttons: [
          { id: "ok", type: "default", text: "Ha" },
          { id: "cancel", type: "destructive", text: "Bekor qilish" }
        ]
      }, (buttonId) => {
        if (buttonId === "ok") {
          // this.submitResults();
          location.reload();
        } else if (buttonId === "cancel") {
          
        }
      });
    },
    reloadPage(){
      location.reload();
    },
    async submitResults() {
      try {
        const result = {
          user: {
            name: this.user.name,
            id: this.user.id,
          },
          score: this.score,
          timestamp: new Date(),
        };
        await addDoc(collection(db, "quizResults"), result);
        // alert("Results submitted successfully!");
      } catch (e) {
        console.error("Error submitting results: ", e);
      }
    },
  },
};
</script>

<style scoped>
.choice-container {
  cursor: pointer;
}

.choice-container.correct {
  background-color: rgba(10, 212, 10, 0.67); /* Light green */
  border-color: green;
  color: #f9f9f9;
}

.choice-container.incorrect {
  background-color: rgba(181, 32, 32, 0.715); /* Light red */
  border-color: red;
  color: #f9f9f9;
}

.v-layout {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.v-flex {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding-bottom: 70px;
}
.v-btn--floating {
    position: fixed; 
    right: 16px; 
    top: 50%; 
    transform: translateY(-50%);

  }
  .zoomed-image {
  max-width: 100%;
  max-height: 100%;
}
</style>
