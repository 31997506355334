var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',[_c('v-row',{staticClass:"text-center my-5"},[_c('v-col',{staticClass:"mb-4 mx-auto mt-n4 selectbox",attrs:{"md":"8"}},[_c('v-card',{staticClass:"card-border",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"pa-0"},[_c('v-row',{staticClass:"mx-0",staticStyle:{"display":"flex"}},[_c('v-col',{staticClass:"d-flex",staticStyle:{"flex-wrap":"wrap","justify-content":"center"},attrs:{"md":"12"}},_vm._l((_vm.categories),function(cat){return _c('v-hover',{key:cat.id,staticClass:"mr-5 mb-5 child",scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{staticClass:"card-color d-flex align-center",class:`elevation-${hover ? 16 : 3}`,staticStyle:{"height":"110px"},style:(hover || _vm.selectedCategory == cat.id
                          ? 'color: white; transform: scale(1.1);'
                          : ''),attrs:{"min-width":"90px","color":hover || _vm.selectedCategory == cat.id
                          ? 'teal lighten-1'
                          : '',"to":cat.route},on:{"click":function($event){_vm.selectedCategory = cat.id;
                        _vm.getService();}}},[_c('div',{staticStyle:{"margin-left":"auto","margin-right":"auto"}},[_c('v-icon',{staticClass:"ma-2",attrs:{"x-large":"","dark":"","color":hover || _vm.selectedCategory == cat.id
                              ? 'white'
                              : 'primary'}},[_vm._v(" "+_vm._s(cat.icon)+" ")]),_c('p',{staticClass:"number-text",staticStyle:{"font-weight":"700"}},[_vm._v(_vm._s(cat.text))])],1)])]}}],null,true)})}),1)],1)],1)],1)],1),_c('v-col',{staticClass:"mx-auto mt-n4 selectdefault",attrs:{"md":"8"}},[_c('v-card',{staticClass:"card-border",attrs:{"flat":""}},[_c('v-card-text',[_c('v-select',{attrs:{"items":_vm.categories,"item-value":"id","label":"Xizmatni tanlang","solo":"","rounded":""},on:{"change":function($event){return _vm.getService()}},model:{value:(_vm.selectedCategory),callback:function ($$v) {_vm.selectedCategory=$$v},expression:"selectedCategory"}})],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }